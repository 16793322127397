import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../img/icons/fontawesome'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import { Container, Heading, Button, Text, FormGroup, FormControl, Label } from '../components/UI'

export const ContactPageTemplate = ({
  title,
  subtitle
}) => (
  <>
    <Helmet>
      <title>Kontakt - SecSafeConsult</title>
      <meta name="description" content="Kontakt mig og hør mere om hvad jeg kan tilbyde netop din virksomhed." />
      <meta property="og:title" content="Kontakt - SecSafeConsult" />
      <meta property="og:url" content="/contact" />
    </Helmet>
    {/* Hero */}
    <Container maxWidth="content" mx="auto">
      <Container mt={["0", "xxxl"]} mb="subsection">
        <Heading textAlign="center" highlight="center" px={["xl", "0"]}>{title}</Heading>
        <Text textAlign="center" fontSize="xl" px={["xl", "0"]}>{subtitle}</Text>
        {/* ContactForm */}
        <Container mt="xxxxl" maxWidth="xxxl" mx="auto">
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <FormGroup px="xl">
              <Label>
                Email
                <FormControl backgroundColor="bg.3" type="email" name="email" placeholder="Skriv din email her..." required />
              </Label>
            </FormGroup>
            <FormGroup mt="xl" px="xl">
              <Label>
                Besked
                <FormControl backgroundColor="bg.3" textarea name="message" rows="6" placeholder="Skriv din besked her..." required />
              </Label>
            </FormGroup>
            <Container px="xl">
              <Button type="submit" color="white" m="0" mt="xl" p="0" px="lg" py="md">
                Send
              </Button>
            </Container>
          </form>
        </Container>
      </Container>
    </Container>
  </>
)

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout isContact>
      <ContactPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        subtitle
      }
    }
  }
`
